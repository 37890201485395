import { Autocomplete, TextField } from '@mui/material';
import { Select } from 'entities/Select';
import { SettingsTemplate } from 'entities/SettingsTemplate';
import { FC, useEffect, useMemo, useState } from 'react';
import { SelectContainer } from './styled';
import { useTranslations } from 'shared/hooks/useTranslations';
import { Loader } from 'app/pageLoader/loaderComponent';
import { useStore } from 'effector-react';
import { settingsStore } from 'shared/store';
import { bindDisplay } from 'shared/store/settingsStore';
import { GetDevicesInfo } from 'shared/types';
import { BindDeviceInput } from 'graphql/generatedModel';
import { handleLocalStorage } from 'shared/helpers/handleLocalStorage';

export const DisplaysBinding: FC<IDisplaysBinding> = ({ getBackToDisplays }) => {
  const t = useTranslations();
  const shippingPoint = handleLocalStorage('shippingPointName');

  // Store
  const {
    displaysData: displaysDataToSet,
    customersList,
    isDisplaysLoading,
    isCustomersLoading,
  } = useStore(settingsStore);

  const { shippingPoints } = useStore(settingsStore);
  const shippingNum = shippingPoints?.find((el) => el?.description === shippingPoint)?.shippingNum;

  const [isDevicesAvailable, setIsDevicesAvailable] = useState<boolean>(false);
  const [updates, setUpdates] = useState<BindDeviceInput[]>([]);

  const [devicesInfo, setDevicesInfo] = useState<GetDevicesInfo>([]);
  const [selectedDeviceSN, setSelectedDeviceSN] = useState<string>('');
  const [currentCustomerId, setCurrentCustomerId] = useState<number>(0);

  // useEffect
  useEffect(() => {
    setIsDevicesAvailable(
      Boolean(displaysDataToSet?.filter((display) => !display?.sku?.length)?.length),
    );
    setDevicesInfo(displaysDataToSet?.filter((display) => !display?.sku?.length));
  }, [displaysDataToSet]);

  useEffect(() => {
    setSelectedDeviceSN(devicesInfo?.[0]?.serialNumber || '');
  }, [devicesInfo?.[0]?.serialNumber]); // eslint-disable-line

  const headerText = useMemo(() => {
    if (isDevicesAvailable) {
      return t('settings.chooseSNToBind', {
        shippingPoint,
      });
    } else {
      return t('settings.allDisplaysBound');
    }
  }, [isDevicesAvailable]); // eslint-disable-line

  const handleCustomerNameUpdate = (_event: any, newValue: any) => {
    setCurrentCustomerId(newValue?.customerId);
    setUpdates((prevValue: any) => {
      const selectedDevice = prevValue.find((pvEL: any) => pvEL.serialNumber === selectedDeviceSN);
      const deviceToSet = {
        serialNumber: selectedDeviceSN,
        customerName: newValue.internalOrFullName,
        sku: newValue.customerNo,
      };
      if (selectedDevice) {
        const isCustomerMatch = devicesInfo.find((diEL) => diEL?.sku === newValue.customerNo);
        return isCustomerMatch
          ? prevValue?.filter((el: any) => el?.serialNumber !== selectedDeviceSN)
          : [...prevValue?.filter((el: any) => el?.serialNumber !== selectedDeviceSN), deviceToSet];
      } else {
        return [...prevValue, deviceToSet];
      }
    });
  };

  const updateBinds = () => {
    bindDisplay({
      params: updates?.map((el) => ({ ...el, shippingPoint, shippingNum })),
    });
    getBackToDisplays();
  };

  return (
    <SettingsTemplate headerTitle={headerText} onSave={updateBinds}>
      <SelectContainer>
        <Select
          width="375px"
          value={selectedDeviceSN}
          handleValue={(sn: string) => {
            setSelectedDeviceSN(sn);
            setCurrentCustomerId(Number(updates?.find((el) => el.serialNumber === sn)?.sku || 0));
          }}
          label={t('settings.serialNumber')}
          options={devicesInfo}
          keys={{
            optionValue: 'serialNumber',
            optionLabel: 'serialNumber',
          }}
        />
        {/* TODO: 99 consider moving Autocomplete to entities */}

        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={isDevicesAvailable ? customersList?.filter((el) => el?.customerId) : []}
          sx={{ width: 375 }}
          value={
            customersList?.find((el) => {
              return el?.customerId === currentCustomerId;
            }) || null
          }
          getOptionLabel={(option: any) =>
            `${option?.customerNo} ${option?.internalOrFullName}` || ''
          }
          renderInput={(params) => {
            return <TextField {...params} label={t('common.companyName')} />;
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option?.customerId}>
                {`${option?.customerNo} ${option?.internalOrFullName}`}
              </li>
            );
          }}
          onChange={handleCustomerNameUpdate}
        />
      </SelectContainer>
      <Loader isLoading={isDisplaysLoading || isCustomersLoading} />
    </SettingsTemplate>
  );
};

interface IDisplaysBinding {
  getBackToDisplays: () => void;
}
