import { GetCustomersList, GetDevicesInfo, ShippingPoints, Translations } from 'shared/types';

export type LangType = 'en' | 'de';
export type PrinterDataType = {
  localIP: string;
  printerId: string;
  isOnline: boolean;
  shippingPoint?: string;
  isLocalConnection: boolean;
};

export type SettingsStoreType = {
  lang: LangType;
  shippingPoints: ShippingPoints;

  translations: Translations;
  isTranslationsInit: boolean;

  isPrinterDataInit: boolean;
  printersList: PrinterDataType[];

  displaysData: GetDevicesInfo;
  isDisplaysLoading: boolean;

  isCustomersLoading: boolean;
  customersList: GetCustomersList;
};

export const initPrintersList = [
  {
    printerId: '',
    localIP: '',
    isLocalConnection: false,
    isOnline: false,
    shippingPoint: undefined,
  },
];

export const initSettingsStore = {
  lang: 'de' as LangType,
  shippingPoints: [],

  translations: {},
  isTranslationsInit: false,

  isPrinterDataInit: false,
  printersList: initPrintersList,

  displaysData: [],
  isDisplaysLoading: false,

  isCustomersLoading: false,
  customersList: [],
};
