import { useStore } from 'effector-react';
import { Button } from 'entities/Button';
import { AvailableStatus, PageNamesEnum } from 'graphql/generatedModel';
import { FC, useEffect, useState } from 'react';
import { withModalHOC } from 'shared/HOCs/withModalHOC';
import { useTranslations } from 'shared/hooks/useTranslations';
import { appStore, globalStore } from 'shared/store';
import { productionStore } from 'shared/store';
import { updateOrders } from 'shared/store';
import { closeProduct } from 'shared/store/productionListStore';
import { ButtonContainer, FinishModalContainer, MainContainer } from './styled';
import { ModalButtons } from '../ModalButtons';
import { DATE_FORMAT } from 'shared/globalConsts';
import { updateDisplay } from 'shared/api';
import { getDistributorId } from 'shared/helpers/getDistributorId';
import { Text } from 'entities/Text';
import { useGetSkuShippingPointNum } from 'shared/hooks/useGetSkuShippingPointNum';

export const FinishModalComponent: FC<IInProgressModal> = ({
  productProps,
  startClosing,
  instantClose,
}) => {
  const [product, setProduct] = useState<any>();
  //   const [date, setDate] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { shippingPoint: shippingPointName } = useStore(appStore);
  const getSkuShippingNum = useGetSkuShippingPointNum();

  const t = useTranslations();

  //
  const { currentOpenProduct } = useStore(productionStore);
  const date = useStore(globalStore).date.format(DATE_FORMAT);

  useEffect(() => {
    setProduct(productProps || currentOpenProduct);
  }, [productProps, currentOpenProduct]);
  //

  const getStatusForUpdate = (order: any) =>
    order?.distributed + order?.toDistribute < order?.amount
      ? AvailableStatus.PENDING
      : AvailableStatus.DONE;

  const moveToDone = async () => {
    startClosing();
    setIsLoading(true);

    const ordersToUpdate = product?.productionListOrders?.filter(
      (order: any) =>
        order?.productStatus !== 'DONE' && order?.productStatus !== 'Done' && order?.toDistribute,
    );
    ///
    await updateOrders({
      date,
      productId: Number(product?.articleId),
      productName: product?.description,
      // TODO: 99 remove pageName when BE is ready
      pageName: PageNamesEnum.PRODUCTION,
      shippingName: shippingPointName || '',
      fields: ordersToUpdate?.map((order: any) => {
        return {
          orderId: Number(order?.orderId),
          partNum: product?.deliveryCycleNum,
          productStatus: getStatusForUpdate(order),
          distributed: Number(order.distributed) + Number(order.toDistribute),
          toDistribute: 0,
        };
      }),
    });

    await updateDisplay({
      date,
      productName: product?.description,
      productStatus: AvailableStatus.DONE,
      shippingName: shippingPointName || '',
      customers: ordersToUpdate?.map((order: any) => ({
        sku: getSkuShippingNum(order.customerNum),
        customerId: Number(order?.customerId),
        distributorId: getDistributorId(order?.responsibleId as number),
      })),
    });

    instantClose();
  };

  const getTotalProduced = () => {
    return product?.productionListOrders?.reduce((acc: number, curr: any) => {
      return acc + Number(curr.toDistribute || 0) + Number(curr.distributed || 0);
    }, 0);
  };

  return (
    <FinishModalContainer>
      <MainContainer>
        <Text>{t('production.produced')}:</Text>
        <Text>{t('common.ordered')}:</Text>
        <Text>{getTotalProduced()}</Text>
        <Text>{product?.sumAmount}</Text>
      </MainContainer>
      <ButtonContainer>
        <Button variant="contained" isLoading={isLoading} handleClick={moveToDone}>
          {t('production.finishDistributing')}
        </Button>
      </ButtonContainer>
      <ModalButtons
        closeMotherModal={closeProduct}
        ordersToDisplay={product?.productionListOrders}
      />
    </FinishModalContainer>
  );
};

interface IInProgressModal {
  productProps?: any;
  startClosing: () => void;
  instantClose: () => void;
}

export const FinishModal = withModalHOC(FinishModalComponent);
