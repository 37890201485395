// import { deleteCell, resetCell } from './styles';

import { IsOnlineCell } from './styles';

export const getColumns = (t: (value: string) => string) =>
  //   openSN: (value: number) => void,
  //   openReset: (value: string) => void,
  //   t: (value: string) => string,
  [
    {
      field: 'isOnline',
      headerName: '',
      renderCell: IsOnlineCell,
      width: 20,
      align: 'center' as any,
    },
    {
      field: 'shippingPoint',
      headerName: t('common.shippingPoint'),
      width: 120,
      align: 'center' as any,
    },
    {
      field: 'printerId',
      headerName: t('common.printerId'),
      width: 140,
      editable: true,
    },
    {
      field: 'localIP',
      headerName: t('settings.ipAddress'),
      flex: 1,
      editable: true,
    },
    {
      field: 'isLocalConnection',
      headerName: t('common.useLocalConnection'),
      width: 150,
      align: 'center' as any,
      type: 'boolean',
      editable: true,
    },
  ];
