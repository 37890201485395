import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { useTranslations } from 'shared/hooks/useTranslations';
import { settingsStore } from 'shared/store';
import { getColumns } from './consts';
import { Table } from 'entities/Table';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { PrinterDataType } from 'shared/store/settingsStore/types';
import { DisplaysContainer } from 'features/Displays/styles';
import { ButtonsContainer } from 'entities/SettingsTemplate/styles';
import { Button } from 'entities/Button';
import { updatePrinterSettings } from 'shared/store/settingsStore';

export const Printers = () => {
  // Hooks
  const apiRef = useGridApiRef();
  const t = useTranslations();

  // Store
  const printersList = useStore(settingsStore).printersList;
  const { isPrinterDataInit } = useStore(settingsStore);
  const { shippingPoints } = useStore(settingsStore);

  const [dataToUpdate, setDataToUpdate] = useState<Partial<PrinterDataType>[]>([]);
  const [printersListToDisplay, setPrintersListToDisplay] = useState<Partial<PrinterDataType>[]>(
    [],
  );

  // useEffect
  useEffect(() => {
    setPrintersListToDisplay(
      shippingPoints
        ?.map((sP) => {
          const printerData = printersList?.find(
            (printer) => printer.shippingPoint === sP?.description,
          );
          const printerDataUpd = dataToUpdate?.find(
            (printer) => printer.shippingPoint === sP?.description,
          );
          if (printerDataUpd) {
            return printerDataUpd;
          } else if (printerData) {
            return printerData;
          } else {
            return {
              shippingPoint: sP?.description || undefined,
            };
          }
        })
        ?.filter((e) => e.shippingPoint),
    );
  }, [shippingPoints, printersList, dataToUpdate]);

  // Functions

  const handleUpdate = async () => {
    if (Boolean(dataToUpdate?.length)) {
      const updatePromises = dataToUpdate.map((data) => {
        if (data.printerId) {
          return updatePrinterSettings({
            params: {
              localIP: data.localIP,
              printerId: data.printerId,
              isLocalConnection: data.isLocalConnection,
              shippingPoint: data.shippingPoint || '',
            },
          });
        }
      });
      await Promise.all(updatePromises);
      setDataToUpdate([]);
    }
  };

  const handleRowEditCommit = (newValue: PrinterDataType) => {
    if (dataToUpdate?.find((el) => el.shippingPoint === newValue.shippingPoint)) {
      setDataToUpdate((prevValue) => {
        return prevValue?.map((prevPrintData) => {
          if (prevPrintData.shippingPoint === newValue.shippingPoint) {
            return newValue;
          } else {
            return prevPrintData;
          }
        });
      });
    } else {
      setDataToUpdate((prevValue) => [...prevValue, newValue]);
    }
  };

  return (
    <DisplaysContainer>
      <div />
      <Table
        apiRef={apiRef}
        loading={!isPrinterDataInit}
        getRowId={(item) => item?.shippingPoint}
        columns={getColumns(t)}
        rows={printersListToDisplay}
        onProcessRowUpdateError={(error: any) => console.error(error?.message)}
        processRowUpdate={handleRowEditCommit}
      />
      <ButtonsContainer>
        <Button
          variant="contained"
          isLoading={!isPrinterDataInit}
          isDisabled={Boolean(!dataToUpdate?.length)}
          handleClick={handleUpdate}
          height="42px"
          width="500px"
        >
          {t('buttons.save')}
        </Button>
      </ButtonsContainer>
    </DisplaysContainer>
  );
};
