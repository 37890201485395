import styled from 'styled-components';

type OnlineIndicatorProps = {
  isOnline: boolean;
};

export const PrinterFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 40px 56px;
  gap: 20px;
`;

export const UseLocalConnectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const OnlineIndicator = styled('div')<OnlineIndicatorProps>`
  width: 10px;
  height: 10px;
  border-radius: 25px;
  background-color: ${({ isOnline }) => (isOnline ? 'green' : 'red')};
`;

export const CellComponent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const IsOnlineCell = (el: any) => {
  return (
    <CellComponent>
      <OnlineIndicator isOnline={el.row.isOnline} />
    </CellComponent>
  );
};
