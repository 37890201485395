import { useStore } from 'effector-react';
import { cleanTerminalString } from 'shared/helpers/clearSPString';
import { appStore, settingsStore } from 'shared/store';

export const useGetSkuShippingPointNum = () => {
  const { shippingPoint } = useStore(appStore);
  const { displaysData } = useStore(settingsStore);
  const getSkuShippingPointNum = (sku: string) => {
    return (
      displaysData?.find(
        (el) =>
          el?.sku === sku &&
          cleanTerminalString(el.shippingPoint || '') === cleanTerminalString(shippingPoint || ''),
        // TODO: remove ts-ignore when BE is deployed
        // @ts-ignore
      )?.skuShippingNum || sku
    );
  };
  return getSkuShippingPointNum;
};
