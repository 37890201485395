import styled from 'styled-components';

export const SettingsContainer = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
`;

export const ModalContainer = styled.div`
  margin: 30px 64px;
  min-width: 780px;
  min-height: 470px;
  @media (max-width: 800px) {
    min-width: unset;
    margin: 0 auto;
  }
`;
