import { FC, useEffect, useState } from 'react';

import { Printers } from 'features/Printers';
import { Displays } from 'features/Displays';
import { DisplaysBinding } from 'features/DisplaysBinding';
import { LanguageSelector } from 'features/LanguageSelector';

import { TabsController } from 'entities/TabsController';
import { useTranslations } from 'shared/hooks/useTranslations';
import { withModalHOC } from 'shared/HOCs/withModalHOC';
import { RenderIf } from 'shared/helpers/renderIf';
import { getClisentsList } from 'shared/store/settingsStore';

import { settingsTabs } from './consts';
import { ModalContainer, SettingsContainer } from './styles';
import { getLayout } from 'shared/helpers/getLayout';

export const Settings: FC = () => {
  const t = useTranslations();
  const tabsState = useState(0);

  useEffect(() => {
    getClisentsList();
  }, []);

  return (
    <SettingsContainer>
      <ModalContainer>
        <RenderIf condition={getLayout() === 'DESKTOP'}>
          <TabsController tabs={settingsTabs(t)} tabsState={tabsState} />
          <RenderIf condition={tabsState[0] === 0}>
            <LanguageSelector />
          </RenderIf>
          <RenderIf condition={tabsState[0] === 1}>
            <Displays />
          </RenderIf>
          <RenderIf condition={tabsState[0] === 2}>
            <DisplaysBinding getBackToDisplays={() => tabsState[1](1)} />
          </RenderIf>
          <RenderIf condition={tabsState[0] === 3}>
            <Printers />
          </RenderIf>
        </RenderIf>
        <RenderIf condition={getLayout() === 'MOBILE'}>
          <LanguageSelector />
        </RenderIf>
      </ModalContainer>
    </SettingsContainer>
  );
};

export const SettingsModal = withModalHOC(Settings);
